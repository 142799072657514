<template>
  <ai-link v-if="reviews > 0" :url="hotelPage">
    <div
      :class="[`AiRating--${variant}`, { 'AiRating--background': background }]"
      class="AiRating">
      <div :class="`AiRating-wrapper--${type}`" class="AiRating-wrapper">
        <span class="AiRating-star-content">
          <ai-typo as="span" class="sr-only">
            {{
              $t('accessibility.description.hotelRatingOn', {
                ratingOn: rating,
              })
            }}
          </ai-typo>
          <ai-icon
            v-for="index in activeRating"
            :key="`AiRating-${index}`"
            :name="type"
            :size="size"
            class="AiRating-item" />
          <ai-icon
            v-if="halfRating"
            :name="`${type}-half` as IconName"
            :size="size"
            class="AiRating-item AiRating-item--half" />
          <ai-icon
            v-for="index in inactiveRating"
            :key="`AiRating-${index}`"
            :name="`${type}-outline` as IconName"
            :size="size"
            class="AiRating-item AiRating-item--empty" />
        </span>
        <span aria-hidden="true" class="AiRating-reviews-container">
          <ai-typo as="span" class="AiRating-score" variant="paragraph-04-bold">
            {{ rating + '/5' }}
          </ai-typo>
        </span>
        <ai-typo
          as="span"
          class="AiRating-reviews"
          variant="paragraph-04-regular">
          {{ reviews + ' ' + $t(`review.label`) }}
        </ai-typo>
      </div>
    </div>
  </ai-link>
</template>

<script lang="ts" setup>
import type { IconName } from '../../atoms/AiIcon/types';

export interface AiRatingProps {
  rating: number;
  reviews: number;
  size?: number;
  background?: boolean;
  type?: 'star';
  variant: 'positive' | 'inverse';
  hotelSlug: string;
  query?: Record<string, string> | null;
}

const localePath = useLocalePath();
const props = withDefaults(defineProps<AiRatingProps>(), {
  background: true,
  reviews: 0,
  size: 18,
  type: 'star',
  query: null,
});

const hotelPage = computed(() =>
  localePath({
    name: 'hotel-hotelSlug',
    params: { hotelSlug: props.hotelSlug },
    hash: '#client-reviews',
    query: props.query ?? {},
  }),
);

const halfRating = computed(() => (props.rating * 10) % 10 === 5);
const activeRating = computed(() => Math.floor(props.rating));
const inactiveRating = computed(
  () => 5 - activeRating.value - (halfRating.value ? 1 : 0),
);
</script>

<style lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/constants';

.AiRating-wrapper {
  display: flex;
  align-items: center;

  &--star {
    .AiRating-item {
      fill: colors.$gold-500;

      &--half {
        fill: none;
      }

      &--empty {
        fill: none;
      }
    }
  }
}

.AiRating {
  display: flex;
  flex-direction: column;
  @include mixins.rem-fallback(gap, 10);
  @include mixins.rem-fallback(max-height, 84);

  &--background {
    padding: constants.$padding-01 constants.$padding-02;
    background: colors.$gold-100;
    backdrop-filter: blur(4px);
    border-radius: 100px;
  }

  &--inverse {
    background: rgba(26, 22, 56, 0.65);

    span {
      color: colors.$white;
    }

    .AiRating-reviews {
      text-decoration: underline;
    }
  }

  &-star-content {
    display: flex;
    flex-direction: row;
  }

  &-score {
    &::after {
      content: '.';
      position: relative;
      left: 10px;
      bottom: 3px;
      font-weight: 500;
    }

    margin: 0 20px 0 15px;
  }

  &-reviews {
    margin-right: 5px;
  }
}

.AiRating-reviews-container {
  display: flex;
}
</style>
